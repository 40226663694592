<template>
  <v-row no-gutters class="mb-2">
    <v-col cols="12">
      <v-layout row align-center>
        <v-select
          :items="pageLanguages"
          v-model="pageDefaultLanguage"
          single-line
          class="pr-2 max-w-select-lang-login"
          hide-details="auto"
          outlined
          dense
        ></v-select>
        <template v-for="(translatedValue, translatedValueIndex) in page.Name">
          <v-text-field
            v-if="pageDefaultLanguage == translatedValueIndex"
            :key="translatedValueIndex"
            :label="$t('Name')"
            v-model="page.Name[translatedValueIndex]"
            single-line
            required
            :rules="[
              (v) => !!v || $t('Name is required'),
              (v) =>
                v.length >= 2 || $t('Minimum required characters is') + ' 2',
            ]"
            validate-on-blur
            hide-details="auto"
            outlined
            dense
          ></v-text-field>
        </template>
        <v-btn
          depressed
          :disabled="page._id.$oid == 0"
          icon
          color="primary"
          v-on:click="copyItem()"
          class="ml-2"
        >
          <v-icon>mdi-plus-box-multiple</v-icon>
        </v-btn>
        <router-link
          :is="page._id.$oid == 0 ? 'span' : 'router-link'"
          :to="{
            name: 'editPageDetails',
            params: {
              name: getPageName(page.Name[currentLanguage]),
              websiteId: websiteId,
              id: page._id.$oid,
            },
          }"
        >
          <v-btn depressed :disabled="page._id.$oid == 0" icon color="primaryorange">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </router-link>
        <v-btn
          depressed
          :disabled="page._id.$oid == 0"
          icon
          color="primaryred"
          v-on:click="removePage()"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-layout>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["page", "websiteId"],
  data() {
    return {
      pageLanguages: null,
      pageDefaultLanguage: null,
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  created() {
    this.pageLanguages = Object.keys(this.page.Name);
    this.pageDefaultLanguage = this.pageLanguages[0];
  },
  methods: {
    getPageName(name) {
      const words = name.split(" ");
      return words.join("");
    },
    removePage() {
      this.$emit("removePage");
    },
    copyItem() {
      this.$emit("addWebsiteCopy", this.page._id.$oid);
    },
  },
};
</script>

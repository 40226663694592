var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-select',{staticClass:"pr-2 max-w-select-lang-login",attrs:{"items":_vm.pageLanguages,"single-line":"","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.pageDefaultLanguage),callback:function ($$v) {_vm.pageDefaultLanguage=$$v},expression:"pageDefaultLanguage"}}),_vm._l((_vm.page.Name),function(translatedValue,translatedValueIndex){return [(_vm.pageDefaultLanguage == translatedValueIndex)?_c('v-text-field',{key:translatedValueIndex,attrs:{"label":_vm.$t('Name'),"single-line":"","required":"","rules":[
            (v) => !!v || _vm.$t('Name is required'),
            (v) =>
              v.length >= 2 || _vm.$t('Minimum required characters is') + ' 2',
          ],"validate-on-blur":"","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.page.Name[translatedValueIndex]),callback:function ($$v) {_vm.$set(_vm.page.Name, translatedValueIndex, $$v)},expression:"page.Name[translatedValueIndex]"}}):_vm._e()]}),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","disabled":_vm.page._id.$oid == 0,"icon":"","color":"primary"},on:{"click":function($event){return _vm.copyItem()}}},[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1),_c(_vm.page._id.$oid == 0 ? 'span' : 'router-link',{tag:"router-link",attrs:{"to":{
          name: 'editPageDetails',
          params: {
            name: _vm.getPageName(_vm.page.Name[_vm.currentLanguage]),
            websiteId: _vm.websiteId,
            id: _vm.page._id.$oid,
          },
        }}},[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.page._id.$oid == 0,"icon":"","color":"primaryorange"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-btn',{attrs:{"depressed":"","disabled":_vm.page._id.$oid == 0,"icon":"","color":"primaryred"},on:{"click":function($event){return _vm.removePage()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }